<template>
  <div class="container">
    <div class="houses__wrapper screen__wrapper">
      <CardHouse
        v-for="buyer in buyers"
        :key="buyer.id"
        :buyer="buyer"
        @click.native="info(buyer)"
      />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import CardHouse from '@/views/Buyers/Card'
import InfoBuyer from '@/views/Buyers/Info'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: "FavoriteBuyersHouses",
  components: {
    CardHouse,
    InfiniteLoading
  },
  computed: {
    buyers() {
      return this.$store.getters.buyersFavHouse;
    },
    hasMoreResults () {
      return this.buyers.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters.paginationFavBuyersHouse.total
    }, 
    page() {
      return this.$store.getters.pageFavBuyersHouse
    },
    class() {
      return this.$route.meta.class
    }
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    info(buyer) {
      const style = this.$modal.styles
      this.$modal.show(
        InfoBuyer, 
        {buyer}, 
        {...style, name: 'InfoBuyer'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
        );
    },
    async loadObj() {
      this.isLoading = true
      try {
        await this.$store.dispatch("fetchFavoritesBuyers", 'house')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
  },
  // beforeDestroy() {
  //   if (Object.keys(this.$store.getters.sortParamsBuyers).length !== 0 || Object.keys(this.$store.getters.filterParamsBuyers).length !== 0) {
  //     this.$store.commit('sortParamsBuyers', {})
  //     this.$store.commit('filterParamsBuyers', {})
  //     this.$store.commit('filterParamsBuyersActive', 0)
  //     this.$store.commit('resetFavBuyers', 'house')
  //     this.loadObj()
  //   }
  // },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>
